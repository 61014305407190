<template>
  <div class="e8-affiliate-dashboard">
    <div v-if="loaded">

      <b-card class="shadow-card bg-dark-main text-header">
        <affiliate-link />
      </b-card>
      <!--      </b-form-group>-->
      <b-card
        class="shadow-card bg-dark-main"
        body-class="p-1"
      >

        <product-payout-row />

      </b-card>
      <b-card
        class="shadow-card bg-dark-main"
        body-class="p-1"
      >
        <div class="v-center justify-content-between px-2 flex-column flex-md-row">
          <span class="text-header text-center font-size-20">Total available profit to be paid</span>
          <b-badge class="bg-light-success px-75 py-50 font-size-16 text-success my-1">
            {{ formatCurrency(availableToPayout, 'USD') }}
          </b-badge>
          <b-button
            title="The minimum withdrawal for affiliate is $200."
            size="sm"
            class="text-header bg-primary px-75 py-50 font-size-16 border-radius-6"
            :class="availableToPayout > 200 ? '':'disabled'"
            @click="askForPayout"
          >
            Ask for payout
          </b-button>
        </div>
      </b-card>
      <b-card
        v-if="ordersFromPayouts.length > 0"
        class="shadow-card bg-dark-main text-header"
        body-class="p-1"
      >
        <h4 class="text-center font-weight-normal font-size-20 mb-3 pt-75">
          Orders
        </h4>
        <orders-table
          :orders="ordersFromPayouts"
        />
      </b-card>
      <div
        class="v-center mt-1 text-warning font-weight-bold"
        style="font-size: 16px"
      >
        <AlertTriangleIcon
          size="22"
          class="mr-50"
        />
        It is prohibited to pay for advertisements with the purpose of acquiring affiliate members.
      </div>
    </div>
    <div v-else>
      <loader />
    </div>
  </div>
</template>

<script>
import { BBadge, BButton, BCard } from 'bootstrap-vue'
import { get } from 'vuex-pathify'
import Swal from 'sweetalert2'
import { AlertTriangleIcon } from 'vue-feather-icons'
import { formatCurrency } from '../../plugins/formatters'
import store from '@/store'
import AffiliateLink from './components/AffiliateLink.vue'
import ProductPayoutRow from './components/ProductPayoutRow.vue'
import Loader from '@/components/Loader.vue'
import OrdersTable from './components/OrdersTable.vue'
import AffAPI from '../../api/affiliate'
import Toastification from './components/ToastComponent.vue'

export default {
  components: {
    BCard,
    BBadge,
    BButton,
    OrdersTable,
    AffiliateLink,
    ProductPayoutRow,
    AlertTriangleIcon,
    Loader,
  },
  data() {
    return {
      loaded: false,

    }
  },
  computed: {
    ...get('auth', ['user']),
    ...get('affiliate', ['affiliate', 'payouts', 'payoutsArray']),
    ...get('affiliate', ['getMonthlyPayouts']),

    ordersFromPayouts() {
      return this.payoutsArray.map(item => ({
        ...item.order,
        profit_amount: item.profit_amount,
      }))
    },
    availableToPayout() {
      let sum = 0
      this.getMonthlyPayouts()
        .forEach(item => {
          const date = item.month.split('-')
          const now = new Date()
          // eslint-disable-next-line eqeqeq
          if (!item.isPaid && !item.askForPayout && !((now.getFullYear() == date[0] && (now.getUTCMonth() + 1) == date[1]))) sum += item.totalPayout
        })
      return sum
    },
    monthsAvailableToPayout() {
      const months = []
      this.getMonthlyPayouts()
        .forEach(item => {
          const date = item.month.split('-')
          const now = new Date()
          // eslint-disable-next-line eqeqeq
          if (!item.isPaid && !item.askForPayout && !((now.getFullYear() == date[0] && (now.getUTCMonth() + 1) == date[1]))) months.push(item.month)
        })
      return months
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    formatCurrency,
    getData() {
      this.loaded = false
      store.dispatch('affiliate/getPayouts')
        .then(() => {
          this.loaded = true
        })
    },
    askForPayout() {
      if (this.availableToPayout > 200) {
        AffAPI.postAffiliatePayout(this.monthsAvailableToPayout)
          .then(() => {
            store.dispatch('affiliate/getPayouts')
            Swal.fire({
              title: '<span class="text-header font-size-24">Success</span>',
              html: '<span class="text-main font-size-18">You have successfully asked for payout</span>',
              icon: 'success',
              scrollbarPadding: false,
              background: '#1E1F21',
              confirmButtonColor: '#30D5F1',
            })
          })
          .catch(() => {
            Swal.fire({
              title: '<span class="text-header font-size-24">Unable to ask for payout</span>',
              html: '<span class="text-main font-size-18">There was a problem. Please try again</span>',
              icon: 'error',
              scrollbarPadding: false,
              background: '#1E1F21',
              confirmButtonColor: '#30D5F1',
            })
          })
      } else {
        this.$toast({
          component: Toastification,
          props: {
            title: 'Unable to process!',
            text: 'The minimum withdrawal for affiliate is $200.',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })
      }
    },
  },
}
</script>

<style lang="scss">
.shadow-card {
  //box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  border: none;
  border-radius: 12px !important;
  background-color: white;
  margin-top: 1rem;
}

.cursor-pointer {
  cursor: pointer;
}

.account-selector {
  // Margin+Width because .btn margin
  width: calc(100% + 10px);
  padding-bottom: 15px;
  margin: 0 -5px 20px;
  border-bottom: 1.5px solid $dark-body;

  .btn {
    margin: 0 5px;
    color: $font-light-header;
    font-size: 18px;
    font-weight: bold;
    width: 25%;
    border: none;
    //Prep for border
    //border-left: 1px solid $dark-body;
    transition: color, background-color ease-in-out 0.1s;
    text-decoration: none;
    line-height: 18px;
    padding: 16px;
    border-radius: 7px !important;
    background-color: rgba($dark-body, 30%) !important;

    //Prep for border
    //&:first-child {
    //  border-left: none;
    //}

    &:hover {
      //color: $primary;
      background-color: darken(rgba($primary, 5%), 15%) !important;
    }

    &.active {
      background-color: $dark-body !important;
      color: $primary;
      box-shadow: none !important;
    }
  }

  //Prep for border
  //.btn.active + .btn {
  //  border: none;
  //}

}

.border-radius-6 {
  border-radius: 6px;
}

@media screen and (max-width: map-get($grid-breakpoints, 'md')) {
  .account-selector {
    width: 100%;
    flex-direction: column;
    //padding: 0;
    margin-left: 0;
    margin-right: 0;

    .btn {
      width: 100%;
      margin: 5px 0;
    }
  }
}
</style>
