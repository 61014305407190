<template>
  <div>
    <!--    Variants -->
    <b-card
      class="bg-dark-body border-0"
      body-class="pt-2 px-75 pb-50"
    >
      <div>
        <b-table
          :items="orders"
          :fields="fields"
          striped
          responsive
          small
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by="'createdAt'"
          :sort-desc="true"
          class="custom-scroll custom-scroll-x pb-50"
          table-class="striped-dark-main"
        >
          <template #cell(order_number)="data">
            <span class="text-primary font-weight-bold">{{ data.item.order_number }}</span>
          </template>

          <template #cell(product)="data">
            {{ data.item.product.name }}
          </template>

          <template #cell(price)="data">
            {{ formatCurrency(data.item.price, 'USD') }}
          </template>

          <template #cell(original_price)="data">
            {{ formatCurrency(data.item.original_price, 'USD') }}
          </template>

          <template #cell(payment_datetime)="data">
            {{ formatDateTime(data.item.payment_datetime) }}
          </template>

          <template #cell(createdAt)="data">
            {{ formatDateTime(data.item.createdAt) }}
          </template>
          <template #cell(profit_amount)="data">
            <b-badge class="bg-light-success text-success px-75 py-50 font-size-16 w-100">
              {{ formatCurrency(data.item.profit_amount, 'USD') }}
            </b-badge>
          </template>
        </b-table>
      </div>
    </b-card>
    <!--    -->
    <b-row class="justify-content-between">
      <b-col class="col-12 col-sm-auto ">
        <b-form-group
          class="mb-0 mt-2"
        >
          <div class="d-flex justify-content-center">
            <label class="d-inline-block text-sm-left mb-0 mr-50 text-nowrap">Per page</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              class="w-50  no-focus text-header"
            />
          </div>

        </b-form-group>
      </b-col>
      <b-col class="col-12 col-sm-auto">
        <div class="v-center justify-content-center justify-content-sm-end">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            size="sm"
            class="mt-2 mb-0 flex-wrap"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-col>

    </b-row>
  </div>
</template>

<script>

import {
  BCol, BFormGroup, BFormSelect, BPagination, BRow,
} from 'bootstrap-vue'
import { formatCurrency, formatDateTime } from '../../../plugins/formatters'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
  },
  props: {
    orders: Array,
  },
  data() {
    return {
      fields: [
        {
          key: 'order_number',
          sortable: false,
        },
        {
          key: 'product',
          sortable: true,
        },
        {
          key: 'price',
          sortable: true,
        },
        {
          key: 'original_price',
          sortable: true,
        },
        {
          key: 'createdAt',
          sortable: true,
        },
        {
          key: 'payment_datetime',
          sortable: true,
        },
        {
          key: 'profit_amount',
          sortable: true,
          label: 'Profit',
        },
      ],
      filterOn: ['createdAt'],
      filter: null,
      // stateVariants: {
      //   unpaid: 'danger',
      //   paid: 'success',
      //   pending: 'warning',
      //   canceled: 'primary',
      // },
      pageOptions: [5, 10, 25, 50, 100],
      currentPage: 1,
      perPage: 5,
      totalRows: null,
    }
  },
  watch: {
    orders() {
      this.totalRows = this.orders.length
    },
  },
  created() {
    this.totalRows = this.orders.length
  },
  methods: {
    formatCurrency,
    formatDateTime,
  },
}
</script>

<style lang="scss">

</style>
