<template>
  <div class="d-flex v-center text-success ">
    <feather-icon
      :icon="icon"
      size="24"
      :class="`text-${variant}`"
    />
    <div class="px-2">
      <h5
        v-if="title"
        class="mb-25 font-size-18"
        :class="`text-${variant}`"
      >
        {{ title }}
      </h5>
      <p
        v-if="text"
        class="mb-0 text-header font-size-14"
      >
        {{ text }}
      </p>
    </div>
  </div>
</template>

<script>

export default {
  components: {
  },
  props: {
    variant: {
      type: String,
    },
    icon: {
      type: String,
    },
    title: {
      type: String,
    },
    text: {
      type: String,
    },
    hideClose: {
      type: Boolean,
    },
  },
}
</script>

<style scoped>

</style>
