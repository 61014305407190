const getLocale = () => ((navigator.languages && navigator.languages.length) ? navigator.languages[0] : navigator.language)

const formatDate = value => {
  if (value) {
    const date = new Date(value)
    return date.toLocaleDateString(getLocale())
  }
}

const formatDateTime = value => {
  if (value) {
    const date = new Date(value)
    return `${date.toLocaleDateString(getLocale())} ${date.toLocaleTimeString(getLocale())}`
  }
}

const formatCurrency = (value, currency) => {
  const intl = new Intl.NumberFormat(getLocale(), { style: 'currency', currency })
  return intl.format(value)
}

const formatNumber = (value, denote = '', decimalPlaces = 2) => {
  if (denote === 'k') {
    value /= 1000
  }
  const factorOfTen = 10 ** decimalPlaces
  value = Math.round(value * factorOfTen) / factorOfTen
  const intl = new Intl.NumberFormat(getLocale())

  return `${intl.format(value)}${denote}`
}

export {
  formatDate, formatCurrency, formatDateTime, formatNumber,
}
