<template>
  <div>
    <h4 class="text-center font-weight-normal font-size-20 mb-3 pt-75">
      Your affiliate link
    </h4>

    <b-card
      class="bg-dark-body mb-1"
      body-class="justify-content-between v-center p-0"
    >
      <b-link
        class="py-75 pl-75 aff-link"
        @click="onCopyCode()"
      >
        https://e8markets.com/a/{{ affKey }}
      </b-link>
      <div>
        <b-button
          v-if="affiliate.aff_custom_key === null"
          variant="link"
          class="pr-50"
          @click="onEditCode()"
        >
          <edit-3-icon
            size="25"
          />
        </b-button>
        <b-button
          variant="link"
          class="pr-50"
          @click="onCopyCode()"
        >
          <copy-icon
            size="25"
          />
        </b-button>
      </div>
    </b-card>
    <b-row>
      <b-col class="col-12 col-md-6 mt-50">
        <b-card
          class="trading-bg-1 trading-bg h-100"
          body-class="justify-content-between v-center px-1 py-75 flex-wrap"
        >
          <p class="text-header font-weight-bold font-size-20 my-25">
            Total sold products
          </p>
          <b-badge class="bg-light-primary px-75 py-50 font-size-20 text-primary my-25 ml-auto">
            {{ this.$parent.payouts.statistics.totalSold }}
          </b-badge>
        </b-card>
      </b-col>
      <b-col class="col-12 col-md-6 mt-50">
        <b-card
          class="trading-bg-2 trading-bg h-100"
          body-class="justify-content-between v-center px-1 py-75 flex-wrap"
        >
          <p class="text-header font-weight-bold font-size-20 mb-0 my-25">
            Total profit
          </p>
          <b-badge class="bg-light-success px-75 py-50 font-size-20 text-success my-25">
            {{ formatCurrency(this.$parent.payouts.statistics.totalPayout, 'USD') }}
          </b-badge>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { get, call } from 'vuex-pathify'
import {
  BBadge, BButton, BCard, BCol, BLink, BRow,
} from 'bootstrap-vue'
import { CopyIcon, Edit3Icon } from 'vue-feather-icons'
import Swal from 'sweetalert2'
import Toastification from './ToastComponent.vue'
import { formatCurrency } from '@/plugins/formatters'

export default {

  components: {
    BButton,
    BCard,
    BLink,
    BRow,
    BCol,
    BBadge,
    CopyIcon,
    Edit3Icon,
  },
  data() {
    return {}
  },
  computed: {
    ...get('affiliate', ['affiliate']),
    affKey() {
      return this.affiliate.aff_custom_key === null ? this.affiliate.aff_key : this.affiliate.aff_custom_key
    },
  },
  methods: {
    formatCurrency,
    ...call('affiliate', ['editCustomCode']),
    async onCopyCode() {
      await navigator.clipboard.writeText(`https://e8markets.com/a/${this.affKey}`)
      this.$toast({
        component: Toastification,
        props: {
          title: 'Copied!',
          text: 'Affiliate link is copied to the clipboard',
          icon: 'CopyIcon',
          variant: 'success',
        },
      })
    },
    async onEditCode() {
      if (!this.affiliate.aff_custom_key) {
        const { value: code } = await Swal.fire({
          title: 'Custom affiliate link',
          html: 'You can change this link only once!<br>Custom links can contain only letters within the range of 3-20 characters.',
          input: 'text',
          showCancelButton: true,
          customClass: {
            cancelButton: 'btn btn-secondary',
            confirmButton: 'btn btn-primary mr-2',
          },
          buttonsStyling: false,
          inputValidator: value => {
            if (!/^[a-zA-Z]{3,20}$/.test(value)) {
              return 'Custom links can contain only letters within the range of 3-20 characters.'
            }
          },
        })
        if (code) {
          this.editCustomCode(code).then(() => {
            this.$toast({
              component: Toastification,
              props: {
                title: 'Custom code set!',
                text: 'You can now use your custom affiliate link',
                icon: 'Edit3Icon',
                variant: 'success',
              },
            })
          }).catch(e => {
            this.$toast({
              component: Toastification,
              props: {
                title: 'Unable to set custom code!',
                text: e.status === 409 ? 'This code is already in use. Please try another one.' : 'Unable to set custom code.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
        }
      }
    },
  },
}
</script>
<style lang="scss">
.trading-bg {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: transparent;
}

.trading-bg-1 {
  background-image: url('~@/assets/images/trading-bg-1.png');
}

.trading-bg-2 {
  background-image: url('~@/assets/images/trading-bg-2.png');
}

@media screen and (max-width: map-get($grid-breakpoints, 'md')) {
  .aff-link {
    width: 100%;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
}
</style>
