<template>
  <div class="text-center">
    <svg
      viewBox="0 0 100 100"
      style="width: 100px; height: 100px; margin: 0 auto;"
    >
      <defs>
        <linearGradient
          id="Gradient"
          x1="50%"
          y1="0%"
          x2="50%"
          y2="100%"
        >

          <stop
            offset="0%"
            stop-color="#7A5FFF"
          >
            <animate
              attributeName="stop-color"
              values="#30D5F1; #fe89ce; #30D5F1"
              dur="4s"
              repeatCount="indefinite"
            />
          </stop>

          <stop
            offset="100%"
            stop-color="#01FF89"
          >
            <animate
              attributeName="stop-color"
              values="#fe89ce; #30D5F1; #fe89ce"
              dur="4s"
              repeatCount="indefinite"
            />
          </stop>

        </linearGradient>
      </defs>
      <circle
        class="circle"
        cx="50"
        cy="50"
        r="30"
        fill="none"
      />
    </svg>
  </div>
</template>

<script>
export default {
}
</script>

<style scoped>
  .circle {
    animation: 8s loader ease infinite alternate,  2s loader2 linear infinite;
    stroke: url(#Gradient);
    stroke-linecap: round;
    stroke-width: 5;
    stroke-dasharray: 94.0955352783 94.0955352783;
    stroke-dashoffset: 0;
  }

  @keyframes loader {
    0% {
      stroke-dasharray: 84.0955352783 104.0955352783;
    }
    100% {
      stroke-dasharray: 178.19107055664062 10;
    }
  }
  @keyframes loader2 {
    0% {
      stroke-dashoffset: 0;
    }
    100% {
      stroke-dashoffset: -188.19107055664062;
    }
  }
</style>
