<template>
  <div><b-form-radio-group
         v-model="typeFilter"
         :options="optionsRadio"
         buttons
         button-variant="link"
         class="account-selector"
       />
    <b-card
      v-if="filteredItems.length > 0"

      class="bg-dark-body mx-md-5"
      body-class="monthly-table px-75 pb-25"
    >
      <b-table
        :fields="fields"
        :items="filteredItems"
        striped
        responsive
        :sort-by="'month'"
        :sort-desc="true"
        class="custom-scroll custom-scroll-x pb-50"
        table-class="striped-dark-main"
      >
        <template #cell(month)="data">
          {{ data.item.month }}
        </template>
        <template #cell(totalProducts)="data">
          {{ data.item.totalProducts }}
        </template>
        <template #cell(totalPayout)="data">
          <div class="vh-center">
            {{ formatCurrency(data.item.totalPayout, 'USD') }}
          </div>
        </template>
        <template #cell(status)="data">
          <b-badge
            :class="`bg-light-${status[statusBadge(data.item)].variant} text-${status[statusBadge(data.item)].variant}`"
            class="px-75 py-50 font-size-16"
          >
            {{ status[statusBadge(data.item)].title }}
          </b-badge>
        </template>
      </b-table>
    </b-card>
    <div
      v-else
      class="vh-center"
    >
      <b-badge
        class="bg-light-danger text-danger font-weight-normal px-75 py-50 text-center text-header mb-0 font-size-16 text-wrap"
      >
        You haven't brought any orders here yet.
      </b-badge>
    </div>
  </div>
</template>
<script>
import { formatCurrency } from '../../../plugins/formatters'

export default {
  // props: {
  // items: Array,
  // filter: String,
  // },
  data() {
    return {
      typeFilter: null,
      optionsRadio: [
        { text: 'All', value: null },
        { text: 'E8 Accounts', value: 'e8' },
        { text: 'ELEV8 Accounts', value: 'elev8' },
        { text: 'E8 Track Accounts', value: 'e8track' },
      ],
      status: {
        unable_payout: {
          variant: 'danger',
          title: 'Unavailable',
        },
        able_payout: {
          variant: 'primary',
          title: 'Available',
        },
        pending: {
          variant: 'warning',
          title: 'Pending',
        },
        paid: {
          variant: 'success',
          title: 'Paid',
        },
      },
      fields: [
        {
          label: 'Month',
          key: 'month',
          sortable: true,
        },
        {
          label: 'Sold',
          key: 'totalProducts',
          sortable: true,
        },
        {
          label: 'Total profit',
          key: 'totalPayout',
          sortable: true,
        },
        {
          label: 'State',
          key: 'status',
          sortable: true,
        },
      ],
    }
  },
  computed: {
    filteredItems() {
      return this.$parent.getMonthlyPayouts(this.typeFilter)
    },
  },
  methods: {
    formatCurrency,
    canAskForPayout(item) {
      if (item.askForPayout || item.isPaid) {
        return false
      }
      const date = item.month.split('-')
      const now = new Date()
      // eslint-disable-next-line eqeqeq
      return !((now.getFullYear() == date[0] && (now.getUTCMonth() + 1) == date[1]))
    },
    statusBadge(item) {
      if (item.askForPayout && !item.isPaid) {
        return 'pending'
      }
      if (item.askForPayout && item.isPaid) {
        return 'paid'
      }
      if (!this.canAskForPayout(item)) {
        return 'unable_payout'
      }
      if (this.canAskForPayout(item)) {
        return 'able_payout'
      }
      return 'undefined'
    },
  },
}
</script>

<style lang="scss" scoped>
.payout-row {
  background-color: $dark-body;
  border-radius: 7px;

  &:nth-child(odd) {
    background-color: transparent;
  }
}

.monthly-table::v-deep {
  th, td {
    text-align: center;
  }
}
</style>
